import React, { Component } from 'react';
import IframeResizer from 'iframe-resizer-react'
import {menu_data} from './utils';

class App extends Component {

  constructor(props) {
    super(props);
  }

  render(){
    const {menu_item} = this.props;
    let iframe_src = menu_data["default"].iframe_src;
    if(Object.keys(menu_data).includes(menu_item) ){
      iframe_src = menu_data[menu_item].iframe_src;
    }
    return (
      <div className="App" id="main-div">
        <div  className="container">
          <div style={{ minWidth: '100%', heigth: "100%", display:'flex', flex: "1", padding:"0px"}}> 
            <IframeResizer className="iframe-hand-in-hand"
            log
            scrolling='true'
            src={iframe_src}
            style={{ visibilty:"none",  width: '1px', overflow:'scroll', minWidth: '100%', top:'0px', bottom:'0px'}}
            sizeHeight="true"
            sizeWidth="true"
            heightCalculationMethod="max"
            /> 
          </div>
          <div style={{ minWidth: '100%', heigth: "100%", display:'flex'}}></div>
        </div>
    </div>
    );
  }

}

export default App;