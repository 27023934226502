export const menu_data = {
  default: {
    "path":"/overview",
    "title":"Overview", 
    "iframe_src": "https://data.apps.fao.org/?clean&https://data.apps.fao.org/catalog/terriajs/config/010981fe-0e87-47f1-9569-5699a887364b.json"
  },
  mitigation: {
    "path":"/mitigation",
    "title":"Mitigation", 
    "iframe_src": "https://tableau.apps.fao.org/views/NDC_NAV_Mit_EC/MitigationTarget?:embed=yes"
  },
  adaptation: {
    "path":"/adaptation",
    "title":"Adaptation", 
    "iframe_src": "https://tableau.apps.fao.org/views/NDC_NAV_Adapt_EC/Adaptation?:embed=yes"
  },
  /* country: {
    "path":"/country",
    "title":"NDC Country Profile", 
    "iframe_src": "https://tableau.apps.fao.org/views/Resources_NDC/NDC_Resources?:embed=yes"   
  }, */
  resources: {
    "path":"/resources",
    "title":"Resources", 
    "iframe_src": "https://tableau.apps.fao.org/views/Resources_NDC/NDC_Resources?:embed=yes"
  },
};
